/** @jsx jsx */
import { css } from "@emotion/react";

export const mainSliderTwo = css`
  p,
  h3 {
    color: #000000;
  }
`;

export const sliderContent = css`
  h3 {
    font-family: "obviously-narrow";
    font-variation-settings: "wght" 700;
    color: #EFEFEF;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
    width: 100%;
    max-width: 445px;
    margin-bottom: 16px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    color: #EFEFEF;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: initial;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h3 {
      font-size: 42px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h3 {
      font-size: 42px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    h3 {
      font-size: 42px;
    }
  }
`
